<template>
  <b-modal :active="visible" :can-cancel="!loading" @close="close">
    <div class="domain-input">
      <div class="domain-input-header">
        <p class="domain-input-header-text">
          Please input your client's website
        </p>
        <Button icon="close" type="grey" size="xs" @click="close" />
      </div>
      <div class="domain-input-content">
        <p class="domain-input-content-header">
          We couldn't find your client's company website, please paste it here
        </p>
        <TextInput
          v-model="domain"
          placeholder="Website url"
          @submit="handleSubmit"
        />
      </div>
      <div class="domain-input-footer">
        <Button text="Cancel" type="white" :disabled="loading" @click="close" />
        <Button
          text="Save"
          :disabled="!domain"
          :loading="loading"
          @click="handleSubmit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'OrganisationDomainInput',
  components: {
    TextInput,
    Button
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    domain: ''
  }),
  methods: {
    close() {
      this.$emit('close')
    },
    handleSubmit() {
      if (!this.domain) return
      this.$emit('submit', this.domain)
    }
  }
}
</script>

<style lang="scss" scoped>
.domain-input {
  width: min(45rem, 90vw);
  border-radius: 6px;
  background: white;

  &-header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;

    &-text {
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: auto;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1.5rem 2rem;

    &-header {
      color: #60666b;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
