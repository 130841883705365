<template>
  <div class="orgsearch">
    <p v-if="title" class="orgsearch-title">{{ title }}</p>
    <InlineSearch
      v-if="!organisation && !showCustom && !accountLoading"
      :key="searchKey"
      :show-last="allowCustom"
      :search-function="searchCompany"
      :debounce-ms="includeSuggestions ? 1000 : 500"
      :query-validator="validateQuery"
      :position-fixed="positionFixed"
      placeholder="Search for a company"
      loading-text="Loading organisations..."
      @submit="selectOrganisation"
      @lastClicked="() => (showCustom = true)"
    >
      <template #item="{ item }">
        <OrganisationSearchItem :item="item" />
      </template>
      <template #last="{ query }">
        <div class="orgsearch-search-add">
          <img
            src="@/assets/icons/plus-medium.svg"
            alt=""
            class="orgsearch-search-add-icon"
          />
          <p class="orgsearch-search-add-text">
            Add <span class="bold">{{ query }}</span> as a new company
          </p>
        </div>
      </template>
    </InlineSearch>
    <div v-else-if="showCustom" class="orgsearch-custom">
      <p class="orgsearch-custom-header">
        Please enter the company information
      </p>
      <TextInput
        v-model="customOrganisationLinkedin"
        placeholder="Company LinkedIn url"
      />
      <div class="orgsearch-custom-btns">
        <Button text="Cancel" type="white" @click="clearCustomInput" />
        <Button text="Save" @click="selectCustomOrganisation" />
      </div>
    </div>
    <OrganisationSearchItem
      v-else
      :item="organisation"
      :removable="removable"
      :loading="accountLoading"
      :bordered="true"
      @remove="() => $emit('remove')"
    />
    <OrganisationDomainInput
      :visible="showDomainInput"
      :loading="domainInputLoading"
      @submit="(domain) => selectAccount(domainInputOrganisation, domain)"
      @close="closeDomainInput"
    />
  </div>
</template>

<script>
import InlineSearch from '@c/library/InlineSearch.vue'
import TextInput from '@c/library/TextInput.vue'
import OrganisationSearchItem from './search/OrganisationSearchItem.vue'
import { searchOrganisations } from '@/services/organisationService'
import { createAccount } from '@/services/accountService'
import OrganisationDomainInput from './OrganisationDomainInput.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'OrganisationSearch',
  components: {
    InlineSearch,
    TextInput,
    Button,
    OrganisationSearchItem,
    OrganisationDomainInput
  },
  props: {
    organisation: {
      type: Object,
      default: () => undefined
    },
    createAccount: {
      type: Boolean,
      default: true
    },
    removable: {
      type: Boolean,
      default: true
    },
    allowCustom: {
      type: Boolean,
      default: true
    },
    includeSuggestions: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    positionFixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showCustom: false,
    customOrganisationLinkedin: '',
    accountLoading: false,
    showDomainInput: false,
    domainInputOrganisation: undefined,
    domainInputLoading: false,
    searchKey: 0
  }),
  methods: {
    async searchCompany(query) {
      if (query.includes('https://') && query.includes('linkedin')) {
        this.selectOrganisation({
          linkedin_url: query
        })
        this.searchKey++
        return []
      }
      const res = await searchOrganisations({
        query,
        include_suggestions: this.includeSuggestions
      })
      return res
    },

    validateQuery(query) {
      if (query.includes('https://') && query.includes('linkedin')) {
        this.selectOrganisation({
          linkedin_url: query
        })
        this.searchKey++
        return false
      }
      return true
    },

    selectOrganisation(org) {
      if (this.createAccount) this.selectAccount(org)
      else this.$emit('input', org)
    },

    async selectAccount(org, domain = undefined) {
      try {
        this.accountLoading = true
        this.$emit('loading', true)
        if (domain) this.domainInputLoading = true
        const account = await createAccount({
          workspace_id: this.$route.params.workspace_id,
          linkedin_url: org.linkedin_url,
          ...(domain ? { domain } : {})
        })
        if (domain) this.showDomainInput = false
        this.$emit('input', account)
      } catch (e) {
        let showError = true
        if (
          (e.data?.detail || '')?.includes(
            'Could not find a domain for the company'
          )
        ) {
          this.showDomainInput = true
          this.domainInputOrganisation = org
          showError = false
        }
        if (showError) {
          this.$console.debug('Error while creating company', e)
          this.$toast.error(e, 'saving this company')
        }
      } finally {
        this.accountLoading = false
        this.$emit('loading', false)
        if (domain) {
          this.domainInputLoading = false
          this.closeDomainInput()
        }
      }
    },

    closeDomainInput() {
      this.showDomainInput = false
      this.domainInputOrganisation = undefined
    },

    selectCustomOrganisation() {
      this.selectOrganisation({
        linkedin_url: this.customOrganisationLinkedin
      })
      this.clearCustomInput()
    },

    clearCustomInput() {
      this.customOrganisationLinkedin = ''
      this.showCustom = false
    }
  }
}
</script>

<style lang="scss" scoped>
.orgsearch {
  &-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  &-search {
    &-add {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.6rem;
      width: 100%;
      background: rgba(#000, 0.08);
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);

      &-text {
        & > .bold {
          font-weight: 600;
        }
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }

  &-custom {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;
    padding: 1rem;
    border: 1px solid rgba(#000, 8%);
    border-radius: 8px;
    position: relative;
    margin-bottom: 1rem;

    &-header {
      color: #60666b;
    }

    &-btns {
      align-self: flex-end;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
    }
  }
}
</style>
