import { getClient, getResponseData } from '../../../store/src/axios'

const workspaceClient = getClient('/workspaces')

export async function getAccounts({ workspace_id, query, page, pageSize }) {
  const response = await workspaceClient.get(`/${workspace_id}/accounts/`, {
    params: { search: query, page, pageSize }
  })
  return getResponseData(response)
}

export async function getAccountNews({ workspace_id, account_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/news/`
  )
  return getResponseData(res)
}

export async function createAccount({
  workspace_id,
  linkedin_url,
  domain = undefined
}) {
  const { data } = await workspaceClient.post(`/${workspace_id}/accounts/`, {
    linkedin_url,
    ...(domain ? { domain } : {})
  })
  return data
}

export async function createAccountNote({ workspace_id, account_id, note }) {
  const response = await workspaceClient.post(
    `/${workspace_id}/accounts/${account_id}/notes/`,
    note
  )
  return getResponseData(response)
}

export async function patchAccountNote({
  workspace_id,
  account_id,
  note_id,
  note
}) {
  const response = await workspaceClient.patch(
    `/${workspace_id}/accounts/${account_id}/notes/${note_id}/`,
    note
  )
  return getResponseData(response)
}

export async function removeAccountNote({ workspace_id, account_id, note_id }) {
  const response = await workspaceClient.delete(
    `/${workspace_id}/accounts/${account_id}/notes/${note_id}/`
  )
  return getResponseData(response)
}

export async function getAccountPersons({ workspace_id, account_id, query }) {
  const response = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/persons/`,
    {
      params: { search: query }
    }
  )
  return getResponseData(response)
}

export async function getAccountPersonSuggestions({
  workspace_id,
  account_id
}) {
  const response = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/persons/suggestions/`
  )
  return getResponseData(response)
}
