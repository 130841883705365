var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible, "can-cancel": !_vm.loading },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "domain-input" }, [
        _c(
          "div",
          { staticClass: "domain-input-header" },
          [
            _c("p", { staticClass: "domain-input-header-text" }, [
              _vm._v(" Please input your client's website ")
            ]),
            _c("Button", {
              attrs: { icon: "close", type: "grey", size: "xs" },
              on: { click: _vm.close }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "domain-input-content" },
          [
            _c("p", { staticClass: "domain-input-content-header" }, [
              _vm._v(
                " We couldn't find your client's company website, please paste it here "
              )
            ]),
            _c("TextInput", {
              attrs: { placeholder: "Website url" },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.domain,
                callback: function($$v) {
                  _vm.domain = $$v
                },
                expression: "domain"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "domain-input-footer" },
          [
            _c("Button", {
              attrs: { text: "Cancel", type: "white", disabled: _vm.loading },
              on: { click: _vm.close }
            }),
            _c("Button", {
              attrs: {
                text: "Save",
                disabled: !_vm.domain,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }