var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "org-item", class: { bordered: _vm.bordered } },
    [
      _c(
        "div",
        { staticClass: "org-item-avatar" },
        [
          _c("Avatar", {
            attrs: {
              user: {
                avatar: _vm.loading ? "" : _vm.item.icon || _vm.item.logo
              },
              "fallback-icon": "company",
              size: "m"
            }
          }),
          _vm.loading
            ? _c("div", { staticClass: "org-item-avatar-loading" }, [
                _c("img", {
                  staticClass: "org-item-avatar-loading-spinner",
                  attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
                })
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "org-item-info", class: { loading: _vm.loading } },
        [
          _c(
            "div",
            { staticClass: "org-item-name" },
            [
              _vm.loading
                ? _c("b-skeleton", {
                    attrs: { height: "1.2rem", width: "min(100%, 15rem)" }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.item.name))]),
              !_vm.loading && _vm.item.accountId
                ? _c("img", {
                    staticClass: "org-item-check",
                    attrs: {
                      src: require("@/assets/icons/check-circle.svg"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              !_vm.loading && _vm.item.linkedin_url
                ? _c("img", {
                    staticClass: "org-item-linkedin",
                    attrs: {
                      src: require("@/assets/logo/linkedin.svg"),
                      alt: ""
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return function() {
                          return _vm.handleOpenExternal(_vm.item.linkedin_url)
                        }.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.loading &&
          (_vm.item.followers ||
            (_vm.item.industry && _vm.item.industry.name) ||
            _vm.item.headquarters)
            ? _c("div", { staticClass: "org-item-props" }, [
                _vm.item.followers
                  ? _c("div", { staticClass: "org-item-props-item" }, [
                      _c("img", {
                        staticClass: "org-item-props-item-icon",
                        attrs: {
                          src: require("@/assets/icons/users.svg"),
                          alt: ""
                        }
                      }),
                      _c("p", { staticClass: "org-item-props-item-text" }, [
                        _vm._v(_vm._s(_vm.item.followers) + " followers")
                      ])
                    ])
                  : _vm._e(),
                _vm.item.industry && _vm.item.industry.name
                  ? _c("div", { staticClass: "org-item-props-item" }, [
                      _vm.item.followers
                        ? _c("p", { staticClass: "org-item-props-dot" }, [
                            _vm._v(" • ")
                          ])
                        : _vm._e(),
                      _c("img", {
                        staticClass: "org-item-props-item-icon",
                        attrs: {
                          src: require("@/assets/icons/industry.svg"),
                          alt: ""
                        }
                      }),
                      _c("p", { staticClass: "org-item-props-item-text" }, [
                        _vm._v(_vm._s(_vm.item.industry.name))
                      ])
                    ])
                  : _vm._e(),
                _vm.item.headquarters
                  ? _c("div", { staticClass: "org-item-props-item" }, [
                      (_vm.item.industry && _vm.item.industry.name) ||
                      _vm.item.followers
                        ? _c("p", { staticClass: "org-item-props-dot" }, [
                            _vm._v(" • ")
                          ])
                        : _vm._e(),
                      _c("img", {
                        staticClass: "org-item-props-item-icon",
                        attrs: {
                          src: require("@/assets/icons/location.svg"),
                          alt: ""
                        }
                      }),
                      _c("p", { staticClass: "org-item-props-item-text" }, [
                        _vm._v(_vm._s(_vm.item.headquarters))
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.loading
            ? _c("b-skeleton", {
                attrs: { height: "1.2rem", width: "min(70%, 11.5rem)" }
              })
            : _vm.item.domain
            ? _c(
                "div",
                {
                  staticClass: "org-item-domain",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return function() {
                        return _vm.handleOpenExternal(_vm.item.domain)
                      }.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "org-item-domain-icon",
                    attrs: { src: require("@/assets/icons/globe.svg"), alt: "" }
                  }),
                  _vm._v(" " + _vm._s(_vm.item.domain) + " ")
                ]
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.loading && _vm.removable
        ? _c("Button", {
            attrs: { icon: "close", type: "white", size: "xxs" },
            on: {
              click: function() {
                return _vm.$emit("remove")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }