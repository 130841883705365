var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orgsearch" },
    [
      _vm.title
        ? _c("p", { staticClass: "orgsearch-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      !_vm.organisation && !_vm.showCustom && !_vm.accountLoading
        ? _c("InlineSearch", {
            key: _vm.searchKey,
            attrs: {
              "show-last": _vm.allowCustom,
              "search-function": _vm.searchCompany,
              "debounce-ms": _vm.includeSuggestions ? 1000 : 500,
              "query-validator": _vm.validateQuery,
              "position-fixed": _vm.positionFixed,
              placeholder: "Search for a company",
              "loading-text": "Loading organisations..."
            },
            on: {
              submit: _vm.selectOrganisation,
              lastClicked: function() {
                return (_vm.showCustom = true)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("OrganisationSearchItem", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "last",
                  fn: function(ref) {
                    var query = ref.query
                    return [
                      _c("div", { staticClass: "orgsearch-search-add" }, [
                        _c("img", {
                          staticClass: "orgsearch-search-add-icon",
                          attrs: {
                            src: require("@/assets/icons/plus-medium.svg"),
                            alt: ""
                          }
                        }),
                        _c("p", { staticClass: "orgsearch-search-add-text" }, [
                          _vm._v(" Add "),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(query))
                          ]),
                          _vm._v(" as a new company ")
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              2020208964
            )
          })
        : _vm.showCustom
        ? _c(
            "div",
            { staticClass: "orgsearch-custom" },
            [
              _c("p", { staticClass: "orgsearch-custom-header" }, [
                _vm._v(" Please enter the company information ")
              ]),
              _c("TextInput", {
                attrs: { placeholder: "Company LinkedIn url" },
                model: {
                  value: _vm.customOrganisationLinkedin,
                  callback: function($$v) {
                    _vm.customOrganisationLinkedin = $$v
                  },
                  expression: "customOrganisationLinkedin"
                }
              }),
              _c(
                "div",
                { staticClass: "orgsearch-custom-btns" },
                [
                  _c("Button", {
                    attrs: { text: "Cancel", type: "white" },
                    on: { click: _vm.clearCustomInput }
                  }),
                  _c("Button", {
                    attrs: { text: "Save" },
                    on: { click: _vm.selectCustomOrganisation }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("OrganisationSearchItem", {
            attrs: {
              item: _vm.organisation,
              removable: _vm.removable,
              loading: _vm.accountLoading,
              bordered: true
            },
            on: {
              remove: function() {
                return _vm.$emit("remove")
              }
            }
          }),
      _c("OrganisationDomainInput", {
        attrs: {
          visible: _vm.showDomainInput,
          loading: _vm.domainInputLoading
        },
        on: {
          submit: function(domain) {
            return _vm.selectAccount(_vm.domainInputOrganisation, domain)
          },
          close: _vm.closeDomainInput
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }